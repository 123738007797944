import { useContext } from "react";
import RedirectButton from "../../RedirectButton";
import { AppContext } from "../../../context/appContext";
import songs from "../../../Assets/Music/data";

const Card = ({ img, title, desc, index }) => {
  const { file, setfile, setIsPlaying, isPlaying, setActiveSong } = useContext(AppContext);

  const setMusic = () => {
    // if (index === file) {
    //   return;
    // }

    if (!isPlaying) {
      setActiveSong(songs[index].mp3File);
      setfile(index);
      setIsPlaying(true);
      return;
    } else {
      setIsPlaying(false);
    }
  };

  return (
    <div className="justify-between max-w-[528px] w-full text-black md:text-[16px] md:tracking-[0.48px] md:leading-[20px] text-[14px] tracking-[0.56px] leading-[18px]">
      <img
        src={img}
        alt=""
        className="max-h-[528px] max-w-[528px] w-full"
      />

      <div className="max-w-[467px] mt-[24px] w-full md:mt-[37px]">
        {songs[index].SongName}
        <br />
        <br />
        {songs[index].description}
      </div>

      <div className="flex gap-[36px] md:mt-[30px] mt-[16px]">
        <a
          href={`https://api.wafaudio.com/mixes/download/${4 - index}`}
          download
        >
          <RedirectButton onClick={async () => {
            // await fetch('https://api.wafaudio.com/mixes/download')
          }} text={"Download"} />
        </a>
        <RedirectButton
          onClick={setMusic}
          text={"Play"}
        />
      </div>
    </div>
  );
};

export default Card;
