import React, { useEffect, useRef, useState } from "react";
import Header from "./components/Header";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/index";
import Main from "./Pages/Main/index";
import SP01Info from "./Pages/SP01-info";
import Shop from "./Pages/Shop";
import Listen from "./Pages/Listen";
import Info from "./Pages/Info";
import SoundAdvice from "./Pages/SoundAdvice";
import Formalities from "./Pages/Formalities";
import { useContext } from "react";
import { AppContext } from "./context/appContext";
import Cart from "./components/Cart";
import Menu from "./components/Menu";
import SP01 from "./Pages/Product";
import ScrollToTop from "./ScrollToTop";
import Product from "./Pages/Product";
import ReactGA from 'react-ga4';
import MP3Player from "./components/MP3Player";

import { ShopContext } from "./context/shopContext";
import {
  fetchProductList,
  createCheckout,
  fetchCheckout,
} from "./utils/shopify";
import Popup from "./components/Popup";
import SoundAdviceCMS from "./Pages/SoundAdvice/cms";
import { useStoryblok, StoryblokComponent } from "@storyblok/react";


ReactGA.initialize('G-NQBBB7BLFE');
function App() {
  const { isCartOpen, isMenuOpen, hasSeenPopup, setHasSeenPopup } = useContext(AppContext);
  const { setProductList, setCheckout, productList } = useContext(ShopContext);

  const [loadedPopup, setLoadedPopup] = useState(false);



  useEffect(() => {
    // Check if checkoutId exists in localStorage and if not create a new checkout


    const _checkCheckoutId = async () => {
      const _checkoutId = localStorage.getItem("checkoutId");

      if (!_checkoutId || _checkoutId == "undefined") {
        const newCheckout = await createCheckout();
        localStorage.setItem("checkoutId", newCheckout.id);
        setCheckout(newCheckout);
      } else {
        const checkout = await fetchCheckout(_checkoutId);

        if (!checkout) {
          const newCheckout = await createCheckout();
          localStorage.setItem("checkoutId", newCheckout.id);
          setCheckout(newCheckout);
        } else {
          setCheckout(checkout);
        }

      }
    };

    const customSort = (a, b) => {
      if (a.title == "SP–01") {
        return -1;
      } else if (b.title == "SP–01") {
        return 1;
      } else if (a.title == 'Cable') {
        return -1;
      } else if (b.title == 'Cable') {
        return 1;
      } else {
        return 0;
      };
    };

    // Fetch product list from shopify
    const _fetchProductList = async () => {
      const newProductList = await fetchProductList();
      console.log(newProductList);
      setProductList(newProductList.sort(customSort));
    };

    _checkCheckoutId();
    _fetchProductList();

    setTimeout(() => {
      if (hasSeenPopup || parseFloat(localStorage.getItem('lastSeenPopup')) < (Number(new Date().getTime()) + (2592000 * 1000))) return;
      setLoadedPopup(true);

    }, 5000);
  }, []);

  useEffect(() => {
    if (hasSeenPopup) {
      localStorage.setItem("lastSeenPopup", new Date().getTime());
    }
  }, [hasSeenPopup]);

  let slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

  const story = useStoryblok(slug, { version: "draft" });



  return (
    <BrowserRouter>
      <Header />
      {isCartOpen && <Cart />}
      {isMenuOpen && <Menu />}
      {!hasSeenPopup && loadedPopup && <Popup closeFunc={() => setHasSeenPopup(true)} />}
      <ScrollToTop />

      <Routes>
        <Route
          path="/"
          element={<Main />}
        />

        <Route
          path="/:id"
          element={<Product />}
        />
        <Route
          path="/home"
          element={<Main />}
        />
        <Route
          path="/sp01-info"
          element={<SP01Info />}
        />
        <Route
          path="/shop"
          element={<Shop />}
        />
        <Route
          path="/listen"
          element={<Listen />}
        />
        <Route
          path="/info"
          element={<Info />}
        />
        <Route
          path="/sp01"
          element={<SP01 />}
        />

        <Route
          path="/soundadvice"
          element={<StoryblokComponent blok={story.content} />}
        />

        <Route
          path="/soundadviceold"
          element={<SoundAdvice />}
        />
        <Route
          path="/formalities"
          element={<Formalities />}
        />
      </Routes>
      <MP3Player />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
