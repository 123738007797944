export const SHOPIFY_BACKEND_URL = 'https://api.wafaudio.com';
export const PRODUCT_CART_TITLE_MAP = {
    'SP01': 'SP-01 Speaker (Pair)',
    'Cable': 'Speaker Cable - 10ft (Pair)',
    'Beta wave mat': 'Beta Wave Mat',
    'Alpha wave mat': 'Alpha Wave Mat'
}

export const PRODUCT_ID_MAP = {
    'speaker': '8213940371676',
    'cable': '8203362631900',
    'beta-wave-mat': '8287062229212',
    'alpha-wave-mat': '8287060623580',
    '8213940371676': '8213940371676',
    '8203362631900': '8203362631900',
    '8287060623580': '8287060623580',
    '8287062229212': '8287062229212'

}
