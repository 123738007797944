import Card from "../../components/UI/ShopCard";

import test from "../../Assets/Shop/1.png";
import test1 from "../../Assets/Shop/2.png";
import test2 from "../../Assets/Shop/3.png";
import test3 from "../../Assets/Shop/4.png";
import test4 from "../../Assets/Shop/5.png";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState, useReducer } from "react";
import { ShopContext } from "../../context/shopContext";
import { PRODUCT_ID_MAP } from "../../constants/shopify";
import ReactGA from 'react-ga4';

const Shop = () => {
  const { productList } = useContext(ShopContext)

  ReactGA.send({ hitType: "pageview", page: "/shop", title: "Shop" });

  const ID_MAP = {
    '8213940371676': '/speaker',
    '8203362631900': '/cable',
    '8287060623580': '/alpha-wave-mat',
    '8287062229212': '/beta-wave-mat'
  }

  return (
    <section className="w-full flex justify-center">
      {/* Gradient */}
      <div className="absolute top-0 -z-10 w-full">
        <div className="h-[68px] md:h-[116px] bg-[#E8E8E3]"></div>
        <div className="h-[68px] md:h-[116px] bg-gradient-to-b from-[#E8E8E3] to-[#EDEDEA00]"></div>
      </div>

      <div className="w-ful flex-col relative text-[50px] text-white max-w-screen-3xl flex justify-center">
        <h2 className="text-[#2D3745] text-center mt-[84px] mb-[46px] text-[50px] leading-[40.2px] md:text-[96px] md:leading-[76px] md:mt-[156px] md:mb-[120px]">
          Shop
        </h2>
        <div className="px-[16px] md:px-[24px] mb-[60px] md:mb-[120px] grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 md:gap-x-[24px] gap-x-[16px] md:gap-y-[64px] gap-y-[32px] max-w-full">
          {productList.map((elem) => {
            console.log(elem.title)
            return (
              <Link to={ID_MAP[`${elem.id.split('/')[elem.id.split('/').length - 1]}`]}>
                <Card
                  title={elem.title}
                  price={elem ? elem?.variants[0].price.amount : '0'}
                  img={elem.images[0] ? elem.images[0].src : test}
                // descrition
                // Specs
                // Options
                />
              </Link>
            )
          }
          )}
        </div>

      </div>
    </section>
  );
};

export default Shop;
