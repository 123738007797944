import { useContext, useEffect } from "react";
import close from "../../Assets/buttons/close.svg";
import { AppContext } from "../../context/appContext";
import CartItem from "./CartItem";
import { ShopContext } from "../../context/shopContext";
import { removeItemFromCheckout, updateItemInCheckout } from "../../utils/shopify";
import { PRODUCT_CART_TITLE_MAP } from "../../constants/shopify";

const Cart = () => {
  const { setOpenCart } = useContext(AppContext);
  const { checkout, setCheckout } = useContext(ShopContext);

  const closeCart = () => {
    setOpenCart(false);
  };

  const finaliseCheckout = () => {
    if (checkout) {
      window.open(checkout.webUrl);
    }
  };

  const removeItem = async (lineItemId) => {
    const newCheckout = await removeItemFromCheckout(checkout.id, lineItemId)
    setCheckout(newCheckout);
  };

  const updateQuantity = async (lineItemId, quantity) => {
    const newCheckout = await updateItemInCheckout(checkout.id, lineItemId, quantity)
    console.log(newCheckout)
    setCheckout(newCheckout);
  };

  const getDisplayName = (title) => {
    if (title) {
      const formattedTitle = title.replace(/–/, '');

      return PRODUCT_CART_TITLE_MAP[formattedTitle];
    }
  };


  return (
    // Backdrop
    <div className="w-full h-full">
      <div
        onClick={closeCart}
        className="fixed z-[70] top-0 bottom-0 right-0 left-0 bg-[#303739CC] backdrop-blur-md"
      ></div>
      <div className="flex flex-col flex-wrap lg:max-w-[50%] p-[16px] lg:p-[24px] w-full fixed z-[80] top-0 bottom-0 right-0 bg-[#303739]">
        <h3 className="h-[76px] w-full items-start mb-[15px] md:mb-[55px] flex justify-between">
          {/* Title */}
          <span className="text-[#CFC8C6] md:leading-[76px] leading-[64px] text-[80px] md:text-[96px]">
            Cart
          </span>

          {/* Close */}
          <button
            onClick={closeCart}
            className="md:h-[40px] h-[24px] w-[24px] md:w-[40px] flex justify-center items-center"
          >
            <img
              src={close}
              alt=""
              className="h-[14px] w-[14px] md:h-[22.94px] md:w-[22.94px]"
            />
          </button>
        </h3>

        <ul className="flex-1 scrollbar-hide overflow-hidden scroll-m-3 overflow-y-scroll pb-400">
          {checkout.lineItems.map((elem, idx) => (
            <CartItem key={idx} variant={elem.variant} id={elem.id} name={elem.title ? getDisplayName(elem.title) : ''} amount={elem?.quantity} price={elem?.variant?.price.amount} image={elem.variant.image.src} updateQuantity={updateQuantity} onDelete={() => removeItem(elem.id)} />
          ))}
        </ul>

        <div className="self-end h left-0 w-full bottom-0 relative">
          <div className="absolute w-full h-[80px] bg-gradient-to-b from-[#30373900] to-[#303739] top-[-80px]"></div>

          <div className="md:pt-[10px]  pt-[10px] gap-[16px] md:gap-[24px] flex flex-col items-center w-full bg-[#303739]">
            <h2 className="w-full text-white flex justify-between leading-[20px] text-[16px] sm:text-[24px] sm:leading-[30px] tracking-[0.48px]">
              <span>Total</span>
              <span>${parseFloat(checkout.totalPrice.amount).toLocaleString()} USD</span>
            </h2>
            <button onClick={finaliseCheckout} className="bg-[#CFC8C6] transition-all duration-300 hover:border-[1px] hover:bg-transparent hover:border-[#CFC8C6] hover:text-[#CFC8C6] text-[#303739] py-[20px] rounded-[1000px] w-full sm:h-[76px] flex items-center justify-center h-[60px] sm:text-[24px] sm:leading-[30px] sm:tracking-[0.48px] text-[14px] leading-[18px] tracking-[0.56px]">
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
